<template>
  <!-- begin:: Footer -->
  <footer>
    <!-- <b-container class="h-100">
      <b-row class="links">
        <b-col class="col-12 col-md-8">
          <h2>Quick Links</h2>
          <b-row>
            <b-col>
              <a>HOME</a>
            </b-col>
            <b-col>
              <a>ABOUT MOONS</a>
            </b-col>
            <b-col>
              <a>CLIENTS</a>
            </b-col>
            <b-col>
              <a>FAQ</a>
            </b-col>
          </b-row>
        </b-col>

        <b-col class="col-12 col-md-4">
          <h2>FOLLOW US</h2>
          <b-row>
            <b-col cols="auto">
              <b-avatar src="media/svg/moon-assets/instagram.png" />
            </b-col>
            <b-col cols="auto">
              <b-avatar>
                <b-icon icon="facebook" />
              </b-avatar>
            </b-col>
            <b-col cols="auto">
              <b-avatar><b-icon icon="twitter" /></b-avatar>
            </b-col>
            <b-col cols="auto">
              <b-avatar src="media/svg/moon-assets/whatsapp.png" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row align-v="end">
        <b-col>
          <h6>Copyright © 2020 MOON inc.</h6>
        </b-col>
        <b-col>
          <h6>Privacy Policy</h6>
        </b-col>
        <b-col>
          <h6>Terms & Conditions</h6>
        </b-col>
      </b-row>
    </b-container> -->

    <span> Copyright © 2021 MOON inc.</span>
  </footer>
  <!-- end:: Footer -->
</template>

<style lang="scss" scoped>
footer {
  font-family: Poppins;
  h2 {
    font: normal normal bold 18px/30px Poppins;
    letter-spacing: 0px;
  }
  a {
    font: normal normal bold 14px/30px Poppins;
    letter-spacing: 1.6px;
    color: #ffffff;
  }
  background: #324772 0% 0% no-repeat padding-box;
  color: #fff;
  .links {
    height: 200px;
    padding-top: 30px;
  }
  .badge-secondary {
    background-color: transparent !important;
    font-size: 25px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  font-size: 15px;
}
</style>

<script>
export default {
  name: 'MoonLandingPageFooter',
};
</script>
