<template>
  <b-container fluid>
    <MoonNavbar />
    <transition name="fade-in-up">
      <router-view />
    </transition>
    <Footer />
  </b-container>
</template>

<script>
import MoonNavbar from "@/view/layout/header/MoonLandingPageHeader.vue";
import Footer from "@/view/layout/footer/MoonLandingPageFooter.vue";

export default {
  name: "MoonLandingPageLayout",
  components: {
    MoonNavbar,
    Footer,
  },
  beforeMount() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.container-fluid {
  width: 100%;
  padding: 0px;
}
</style>