<template>
  <b-navbar sticky>
    <b-navbar-brand href="#"
      ><b-img
        src="media/svg/moon-assets/header_logo.png"
        width="70px"
        @click="$router.push({ name: 'landing-page' })"
    /></b-navbar-brand>

    <!-- additional language selector switch for mobile -->
    <b-navbar-brand class="d-md-none">
      <!-- <b-button @click="changeLocale(currentLocale == 'en' ? 'ar' : 'en')">{{
        currentLocale == 'en' ? 'AR' : 'EN'
      }}</b-button> -->
    </b-navbar-brand>

    <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->

    <!-- <b-collapse id="nav-collapse" is-nav> -->
    <!-- Right aligned nav items -->
    <b-navbar-nav class="ml-auto">
      <!-- <b-nav-item> {{ $t('navigation.aboutUs') }} </b-nav-item> -->

      <!-- <b-nav-item> {{ $t('navigation.howItWorks') }} </b-nav-item> -->

      <!-- <b-nav-item> {{ $t('navigation.contactUs') }} </b-nav-item> -->

      <!-- <b-nav-item>{{ $t('navigation.fourth') }} </b-nav-item> -->

      <b-nav-item-dropdown :text="currentLocale == 'en' ? 'ENG' : 'ARA'" right>
        <b-dropdown-item
          v-if="currentLocale == 'ar'"
          @click="changeLocale('en')"
          >ENG</b-dropdown-item
        >
        <b-dropdown-item
          v-if="currentLocale == 'en'"
          @click="changeLocale('ar')"
          >ARA</b-dropdown-item
        >
      </b-nav-item-dropdown>

      <b-nav-item>
        <b-button
          @click="$router.push({ name: 'join', query: { country: country } })"
        >
          <!-- {{ $t("navigation.button") }} -->
          JOIN US
        </b-button>
      </b-nav-item>
    </b-navbar-nav>
    <!-- </b-collapse> -->
  </b-navbar>
</template>

<script>
import { SET_LOCALE } from "@/core/services/store/auth.module";
export default {
  name: "MoonLandingPageNavbar",
  created() {
    const countryParam = this.$route.query.country;
    if (countryParam) {
      // if comes from referal link redirect to referal step.
      this.country = countryParam;
    }
    if (!this.currentLocale) {
      localStorage.setItem("locale", "en");
      this.$store.dispatch(SET_LOCALE, e);
    }
    this.$i18n.locale = this.currentLocale;
  },
  data: () => ({
    country: null,
  }),
  computed: {
    currentLocale() {
      return this.$store.getters.getLocale;
    },
  },
  methods: {
    changeLocale(e) {
      this.$i18n.locale = e;
      localStorage.setItem("locale", e);
      this.$store.dispatch(SET_LOCALE, e);
    },
  },
};
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .navbar {
    padding: 10px !important;
  }

  ::v-deep .dropdown-menu {
    min-width: 65px !important;
    width: 65px !important;
    top: 46px !important;
  }
}
.btn,
.btn:hover,
.btn:focus,
.btn:active {
  background: #324772 0% 0% no-repeat padding-box !important;
  border-radius: 11px;
  // font-family: Montserrat-Arabic;
  font-family: "Mark Pro";

  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0px;
  color: #ffffff !important;
  width: 100px;
  height: 40px;
}
::v-deep .dropdown-menu {
  min-width: 50px !important;
  top: 46px !important;
}
.navbar {
  background: #fff;
  text-align: center;
  font-size: 13px;
  line-height: 40px;
  font-weight: 500;
  color: #000;
  box-shadow: 0px 12px 24px #aaaaaa29;
  height: 70px !important;
  padding: 0px 58px 0px 58px;

  .navbar-collapse {
    ul {
      li {
        a {
          padding: 10px 35px 10px 35px;
          color: black;
          font: normal normal normal 16px/41px "Mark Pro";
          font-weight: 800;
        }
        a:hover,
        a:active {
          color: #324772;
        }
        ::v-deep .dropdown-toggle {
          font-family: "Mark Pro";
          font-size: 16px;
          font-weight: 800;
          line-height: 47px;
          color: black;
        }
        ::v-deep .dropdown-toggle::after {
          color: red;
        }
      }
    }
  }
}
</style>
