import { render, staticRenderFns } from "./Moon-Landing-Page-Layout.vue?vue&type=template&id=8e6b4816&scoped=true"
import script from "./Moon-Landing-Page-Layout.vue?vue&type=script&lang=js"
export * from "./Moon-Landing-Page-Layout.vue?vue&type=script&lang=js"
import style0 from "./Moon-Landing-Page-Layout.vue?vue&type=style&index=0&id=8e6b4816&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e6b4816",
  null
  
)

export default component.exports